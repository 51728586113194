import React from "react";
import Template from "../components/Template";
import Footer from '../components/Footer'
import '../components/test.sass'

export default function Home() {

  return (
    <Template
      topPaddingLess
      activeTab={1}
      title="Home"
    >
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds is-offset-3">
            <h1 className="title has-text-cyan has-text-weight-bold has-text-left pb-6 main-title">
              RESTLESS <br />MONKS
            </h1>
          </div>
        </div>
        <h2 className="subtitle has-text-white is-size-3-tablet is-size-4-mobile has-text-centered">
          Agile Monks Building Micro SaaS Products
        </h2>
        <Footer />
      </div>
    </Template>
  )
}