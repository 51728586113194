import React from 'react'

const Footer = () => {
  return(
    <div className="has-text-centered">
      <div className="button is-large is-text">
        <a className="has-text-radical-red" href="mailto:hello@restlessmonks.com" target="_blank">
          <br /><br />
          Say Hi.
        </a>
      </div>
    </div>
  )
}

export default Footer